import React from "react"
import { graphql } from "gatsby"
import Layout from "./default"
import VideoList from "../components/VideoList"
import CategoryList from "../components/CategoryList"
import CategoryTitle from "../components/CategoryTitle"

const CategoryPage = ({ data }) => {
  const {
    title,
    slug: categorySlug,
    categoryVideos: videos,
    description,
  } = data.contentfulCategory
  const pageData = {
    title: title,
    description: description && description,
  }
  const filteredCategories = data.allContentfulCategory.edges.filter(
    ({ node }) => node.slug !== categorySlug
  )
  return (
    <Layout pageData={pageData}>
      <div className="-mt-4">
        <VideoList videos={videos} categorySlug={categorySlug} />
      </div>
      <div className="mt-12 md:mt-20 row">
        <div className="text-white col">
          <CategoryTitle title="Muut kategoriat" />
          <CategoryList categories={filteredCategories} />
        </div>
      </div>
    </Layout>
  )
}

export default CategoryPage

export const query = graphql`
  query($slug: String!) {
    # Slug comes available from gatsby-node.js's createPage method's context
    contentfulCategory(slug: { eq: $slug }) {
      title
      slug
      description
      categoryVideos {
        slug
        title
        poster {
          fluid(maxWidth: 400, maxHeight: 225) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allContentfulCategory {
      edges {
        node {
          slug
          shortTitle
        }
      }
    }
  }
`
